<template>
  <div class="font-family-raleway-medium my-campaign-details-div" style="position: relative">
    <covered-loader v-if="isLoading"/>
    <v-breadcrumbs :items="items" class="px-0 py-4 ma-0" large>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card class="card">
      <router-link class="router-link" to="/my-campaigns">
        <div class="d-flex align-center">
          <v-icon color="#0057FF">mdi-chevron-left</v-icon>
          <strong>{{ $t('back_button') }}</strong>
        </div>
      </router-link>
      <v-row class="pa-0 ma-0 mt-2 align-center">
        <h1 class="slug">{{ campaign.name }}</h1>
        <v-btn
          class="ml-2 mt-1 edit"
          @click="isUpdatingName = true"
          v-if="campaign.status !== 5"
        >
          <v-icon small class="mr-1">mdi-pencil-outline</v-icon>
          {{ $t('edit_button') }}
        </v-btn>
        <v-spacer></v-spacer>
        <h2>
          <span>
            {{ itemCurrency[campaign.budget.currency] }}
          </span>
          {{ campaign.statistics.cost }}
        </h2>
        <span class="ml-2 mt-1">{{$t('details_page.total_spend')}}</span>
      </v-row>
      <v-row class="align-center ml-0 pl-0">
        <span class="color-gray">{{
          numberWithSpaces(campaign.gCustomerId)
        }}</span>
        <div class="d-flex align-center ml-5">
          <div
            class="d-flex align-center px-5 py-2"
            :class="GETCAMPAIGN_STATUS[campaign.status]==='active_chip' ? 'Active': 'Paused'"
            style="height: 32px; border-radius: 10px"
          >
            <img
              :src="activeIcon"
              v-if="GETCAMPAIGN_STATUS[campaign.status] === 'active_chip'"
              class="mr-1"
              width="14"
              height="14"
            />
            <v-icon
              v-if="GETCAMPAIGN_STATUS[campaign.status] === 'paused_chip'"
              color="#F2994A"
            >
              mdi-pause
            </v-icon>
            <span
              class="ml-1 font-family-raleway-bold text-uppercase"
              style="font-size: 14px"
            >
              {{ $t(GETCAMPAIGN_STATUS[campaign.status]) }}
            </span>
          </div>
        </div>
      </v-row>
      <v-divider class="mt-7"></v-divider>
      <IndicatorsSection
        :campaign="campaign"
        @setCampaign="setCampaign"
        :customerId="campaign.gCustomerId"
      />
      <BillingSection
        :campaignPmId="campaign.pmId"
        :campaignId="campaign.id"
        :status="campaign.status"
      />
      <!--     keywords    -->
      <v-row class="pa-0 ma-0">
        <v-col class="d-flex align-center" cols="12">
          <img :src="searchw" height="20" width="20" />
          <strong class="ml-2">{{ $t("details_page.keywords_title") }}</strong>
          <!--          <v-btn class="edit ml-2" @click="updatingKeywords = true">-->
          <!--            <v-icon small class="mr-1">mdi-pencil</v-icon>-->
          <!--            Edit-->
          <!--          </v-btn>-->
        </v-col>
        <v-col class="mt-0 pt-0" cols="12">
          <v-chip
            v-for="(item, index) of campaign.keywords"
            :key="index"
            class="chip-keyword mr-3 mt-2"
            outlined
          >
            {{ item }}
            <v-btn
              icon
              v-if="updatingKeywords"
              @click="removeKeywordFromList(item)"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-chip>
          <div
            class="d-flex my-5 justify-space-between"
            v-if="updatingKeywords"
          >
            <span></span>
            <div>
              <v-btn
                class="mr-5"
                style="box-shadow: none; background-color: #ffffff"
                height="42"
                @click="cancelEditKeywords"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                class="button font-weight-bold px-5"
                height="40"
                :loading="isUpdatingKeywords"
                @click="saveKeywordChanges"
              >
                {{ $t("save_button") }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="ma-0 pa-0">
        <!--  location  -->
        <v-col class="mt-5" cols="12" md="6">
          <v-row class="align-center ma-0 pa-0">
            <v-icon>mdi-map-marker-outline</v-icon>
            <strong class="ml-2">{{ $t("location") }}</strong>
            <!--            <v-btn class="edit ml-2 edit" @click="updatingLocation = true">-->
            <!--              <v-icon small class="mr-1">mdi-pencil</v-icon>-->
            <!--              Edit-->
            <!--            </v-btn>-->
          </v-row>
          <v-row class="mt-0 pt-0 px-2">
            <v-col cols="12" md="6">
              <span>{{$t("details_page.your_campaign_show")}}</span>
              <p>
                <strong>{{ campaign.campaignTargets.country }}</strong>
              </p>
              <v-autocomplete
                v-if="updatingLocation"
                v-model="select"
                :items="cities"
                item-text="name"
                item-value="criteriaID"
                :search-input.sync="countryValue"
                class="font-family-raleway-medium"
                label="Select area"
                solo-inverted
                style="width: 100%; z-index: 1000"
                @input="handleSelectLocation"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <div style="height: 200px; width: 100%; overflow: hidden">
                <l-map
                  :center="mapGoogle.center"
                  :zoom="mapGoogle.zoom"
                  style="height: 250px; z-index: 0"
                >
                  <l-tile-layer :url="mapGoogle.url"></l-tile-layer>
                  <l-marker :lat-lng="mapGoogle.marker">
                    <l-icon
                      :icon-size="mapGoogle.iconSize"
                      :icon-url="mapGoogle.icon"
                    ></l-icon>
                  </l-marker>
                </l-map>
              </div>
            </v-col>
            <v-col cols="12">
              <div
                class="d-flex my-5 justify-space-between"
                v-if="updatingLocation"
              >
                <span></span>
                <div>
                  <v-btn
                    class="mr-5"
                    style="box-shadow: none; background-color: #ffffff"
                    height="42"
                    @click="cancelEditLocation"
                  >
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    class="button font-weight-bold px-5"
                    height="40"
                    :loading="isUpdatingLocation"
                    @click="saveLocation"
                  >
                    {{ $t("save_button") }}
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <!--   budget  -->
        <v-col cols="12" md="6">
          <v-row class="align-center ma-0 pa-0 mt-5">
            <img :src="budjet" height="20" width="20" />
            <strong class="ml-2">{{ $t("details_page.budget_title") }}</strong>
            <v-btn
              class="edit ml-2 edit"
              @click="isEditBudget = true"
              v-if="campaign.status !== 5"
            >
              <v-icon small class="mr-1">mdi-pencil</v-icon>
              {{ $t("edit_button") }}
            </v-btn>
          </v-row>
          <p>
            {{ $t("details_page.budget_section_description") }}
          </p>
          <v-chip class="mt-1 py-13 pr-16 px-10" color="#F9F9F9">
            <div class="align-center d-flex">
              <div class="daily-avarege">
                <h1 style="font-size: 22px">
                  <span>
                    {{ itemCurrency[campaign.budget.currency] }}
                  </span>
                  <span>
                    {{ campaign.budget.amout*7 }}
                  </span>
                </h1>
                <p class="mb-0 pb-0 mt-1">
                  {{ $t(PERIODS_[campaign.budget.period]) }}
                </p>
              </div>
              <v-divider class="divider pl-4" vertical></v-divider>
              <div class="ml-5" style="white-space: normal">
                <li><strong>US$300</strong> {{ $t("details_page.monthly_max") }}</li>
                <li>
                  {{ $t("details_page.estimate_text")}}
                </li>
              </div>
            </div>
          </v-chip>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0 mt-5">
        <v-col md="12">
          <h4>{{ $t("details_page.campaign_review_title") }}</h4>
          <!--headlines-->
          <div class="text-left mt-5">
            <span
              class="align-center"
              v-for="(item, index) of campaign.adOptions.headlines"
              :key="index"
            >
              <a
                :style="
                  index + 1 === campaign.adOptions.headlines.length
                    ? ''
                    : 'border-right: 2px solid #0057FF!important;'
                "
                class="text-decoration-none color-blue font-family-raleway-medium"
                :class="index === 0 ? 'pr-2' : 'px-2'"
                ><strong>{{ item }}</strong></a
              >
            </span>
          </div>

          <!--descriptions-->
          <div class="pt-2 text-left">
            <span
              class="align-center"
              v-for="(item, index) of campaign.adOptions.descriptions"
              :key="index"
            >
              <a
                :style="
                  index + 1 === campaign.adOptions.descriptions.length
                    ? ''
                    : 'border-right: 1px solid black'
                "
                class="text-decoration-none color-gray"
                :class="index === 0 ? 'pr-2' : 'px-2'"
              >
                {{ item }}</a>
            </span>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row v-if="updatingHeadlines">
            <v-col cols="12" md="6">
              <h4>{{ $t("details_page.headlines") }}</h4>
              <div
                v-for="(item, index) of campaign.adOptions.headlines"
                :key="index"
              >
                <textarea
                  v-model="item.headline"
                  class="input-border textarea-style mt-2 pa-3"
                  :maxlength="30"
                  :rows="2"
                />
                <v-row class="ma-0 d-flex justify-space-between">
                  <span></span>
                  <span>{{ item.headline.length }}/100</span>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <h4>{{ $t("details_page.descriptions") }}</h4>
              <div
                v-for="(item, index) of this.campaign.adOptions"
                :key="index"
              >
                <textarea
                  v-model="item.description"
                  class="input-border textarea-style mt-2 pa-3"
                  :maxlength="90"
                  :rows="3"
                />
                <v-row class="ma-0 d-flex justify-space-between">
                  <span></span>
                  <span>{{ item.description.length }}/200</span>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex my-5 justify-space-between">
                <span></span>
                <div>
                  <v-btn
                    class="mr-5"
                    style="box-shadow: none; background-color: #ffffff"
                    height="42"
                    @click="cancelEditHeadlines"
                  >
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    class="button font-weight-bold px-5"
                    height="40"
                    :loading="isUpdatingHeadlines"
                    @click="saveHeadlines"
                  >
                    {{ $t("save_button") }}
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <!--modal for updating campaign name-->
    <update-campaign-name-modal
      :v-model-dialog="isUpdatingName"
      :campaign-name="campaign.name"
      @cancelModal="handleCancelModal"
    />
    <edit-budget
      :dialog="isEditBudget"
      :budgetAmountDefault="campaign.budget.amout"
      @getCampaignInfos="getcampaignDatas"
      @cancelModal="isEditBudget = false"
      :campaignId="campaignId"
    />
  </div>
</template>
<script>
import searchw from "@/assets/img/searchw.svg";
import budjet from "@/assets/img/budjet.svg";
import { campaignService } from "@/services/campaign";
import { LIcon, LMap, LMarker, LTileLayer } from "vue2-leaflet";
import locationIcon from "../../assets/icon/location-sign-svgrepo-com.svg";
import { CAMPAIGN_STATUSES, numberWithSpaces, PERIODS } from "@/utils";
import activeIcon from "../../assets/icon/active-status-icon.svg";
import { getCities } from "@/services/autocomplete";

export default {
  name: "MyCampaignDetails",
  components: {
    CoveredLoader: () => import("@/components/CoveredLoader"),
    EditBudget: () => import("@/components/dialogs/EditBudget"),
    BillingSection: () => import("@/components/Account/my-campaigns-details/BillingSection"),
    IndicatorsSection: () => import("@/components/Account/my-campaigns-details/IndicatorsSection"),
    UpdateCampaignNameModal: () => import("@/components/Account/my-campaigns-details/UpdateCampaignNameModal"),
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  data() {
    return {
      mapGoogle: {
        zoom: 13,
        center: { lat: 41.2895874, lng: 69.208352 },
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
        icon: locationIcon,
        iconSize: [30, 30],
        marker: { lat: 41.2895874, lng: 69.208352 },
      },
      searchw,
      budjet,
      activeIcon,
      isLoading: false,
      getCampaignsLoading: false,
      isEditBudget: false,
      isUpdatingName: false,
      isUpdatingStatus: false,
      isUpdatingKeywords: false,
      updatingKeywords: false,
      isUpdatingLocation: false,
      updatingLocation: false,
      updatingHeadlines: false,
      isUpdatingHeadlines: false,
      select: null,
      criteriaId: "",
      countryValue: "",
      campaignId: "",
      itemCurrency: {
        0: "$",
      },
      campaign: {
        budget: {
          currency: 0,
        },
        campaignTargets: {
          country: "",
        },
        statistics: {
          name: "",
          clicks: "",
          ctr: "",
          cpc: "",
          costConversions: 4.0,
          cost: "",
          status: "paused",
        },
      },
      items: [
        {
          text: "My campaigns",
          href: "/my-campaigns",
        },
        {
          text: "My Campaign Details",
        },
      ],
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getcampaignDatas();
    } catch (err) {
      const errCode = err.response.statusText;
      this.$notify({
        group: "foo",
        type: "error",
        title: errCode,
      });
    }
    this.isLoading = false;
  },
  methods: {
    async getcampaignDatas() {
      const { id } = this.$route.params;
      this.campaignId = id;
      this.isLoading = true
      const res = await campaignService.getById(id);
      this.setCampaign(res);
      this.isLoading = true
    },
    setCampaign(response) {
      this.mapGoogle.marker.lat =
        response?.data.campaignTargets.adLocation.latitude;
      this.mapGoogle.center.lat =
        response?.data.campaignTargets.adLocation.latitude;
      this.mapGoogle.marker.lng =
        response?.data.campaignTargets.adLocation.longitude;
      this.mapGoogle.center.lng =
        response?.data.campaignTargets.adLocation.longitude;
      this.campaign = response?.data;
    },
    async handleCancelModal() {
      this.isUpdatingName = false;
      await this.getcampaignDatas();
    },
    cancelEditKeywords() {
      this.updatingKeywords = false;
      this.getcampaignDatas();
    },
    cancelEditLocation() {
      this.updatingLocation = false;
      this.getcampaignDatas();
    },
    removeKeywordFromList(item) {
      this.campaign.keywords = this.campaign.keywords.filter(
        (keyword) => keyword !== item
      );
    },
    handleSelectLocation(val) {
      if (val) {
        this.criteriaId = val;
      }
    },
    async saveKeywordChanges() {
      const { id } = this.$route.params;
      const { keywords } = this.campaign;
      this.isUpdatingKeywords = true;
      try {
        await campaignService.updateKeywords(keywords, id);
        this.$notify({
          group: "foo",
          type: "success",
          title: "Successfully updated!",
        });
        await this.getcampaignDatas();
      } catch (e) {
        this.$notify({
          group: "foo",
          type: "error",
          title: e.response?.statusText,
        });
      }
      this.isUpdatingKeywords = false;
    },
    async saveLocation() {
      const { id } = this.$route.params;
      this.isUpdatingLocation = true;
      try {
        await campaignService.updateLocation(id, this.criteriaId);
        this.$notify({
          group: "foo",
          type: "success",
          title: "Successfully updated!",
        });
        await this.getcampaignDatas();
      } catch (e) {
        this.$notify({
          group: "foo",
          type: "error",
          title: e.response?.data,
        });
      }
      this.isUpdatingLocation = false;
    },
    cancelEditHeadlines() {
      this.updatingHeadlines = false;
      this.getcampaignDatas();
    },
    async saveHeadlines() {
      this.isUpdatingHeadlines = true;
      const { id } = this.$route.params;

      const { adGroups } = this.campaign;
      const { ads } = adGroups[0];
      const payload = {
        ...ads[0],
        headlines: this.campaign.adOptions?.map((item) => item.headline),
        descriptions: this.campaign.adOptions?.map((item) => item.description),
      };
      try {
        await campaignService.updateHeadlines(id, payload);
        this.$notify({
          group: "foo",
          type: "success",
          title: "Successfully updated!",
        });
        await this.getcampaignDatas();
      } catch (e) {
        this.$notify({
          group: "foo",
          type: "error",
          title: e.response?.data,
        });
      }
      this.isUpdatingHeadlines = false;
    },
  },
  computed: {
    GETCAMPAIGN_STATUS() {
      return CAMPAIGN_STATUSES;
    },
    PERIODS_() {
      return PERIODS;
    },
    numberWithSpaces() {
      return function (val) {
        return numberWithSpaces(val.toString());
      };
    },
  },
  watch: {
    countryValue(val) {
      if (val) {
        getCities(val)
          .then((res) => {
            this.cities = res;
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.router-link {
  text-decoration: none !important;
}

.chip {
  height: 32px;
  border-radius: 10px;
  padding: 0 15px !important;
  text-transform: uppercase;
}

.down-btn {
  background-color: #f5f9fc !important;
  border-radius: 12px !important;
  box-shadow: none;
}

.select {
  border-radius: 12px !important;
}

.slug {
  @media screen and (max-width: 450px) {
    font-size: 22px;
  }
}

.chip-field {
  height: 120px;
  width: 100%;

  strong {
    position: absolute;
    font-size: 16px;
    color: #828282;
    top: 16px;
    left: 16px;
  }

  h2 {
    color: #0057ff;
    font-size: 36px;
  }
}

.chip-keyword {
  border-radius: 16px;
  height: 48px;
}

//.daily-avarege {
//  @media screen and (max-width: 600px) {
//    display: flex;
//    margin-bottom: 10px;
//  }
//}

.my-campaign-details-div {
  transform: scaleX(0.9) !important;
}

.edit {
  box-shadow: none;
  background-color: transparent !important;
  text-transform: capitalize;
}

.rotate-90 {
  transform: rotate(-90deg) !important;
}

.map {
  width: 100% !important;
}
</style>
